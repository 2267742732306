import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SwiperModule } from "ngx-swiper-wrapper";
import { PipeModule } from "app/shared/pipes/pipe.module";
import { TranslateModule } from '@ngx-translate/core';

import { OrderRoutingModule } from './order-routing.module';
import { OrderComponent } from './order.component';

@NgModule({
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    imports: [
      CommonModule,
      OrderRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      SwiperModule,
      PipeModule,
      TranslateModule,
    ],
    declarations: [
      OrderComponent,
    ],
    exports: [
      OrderComponent,
    ],
    entryComponents: [
      OrderComponent
    ]
})
export class OrderModule {

}
