import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderComponent } from "./order.component";

const routes: Routes = [
  {
    path: '',
    component: OrderComponent,
    children: [
      { path: "", redirectTo: "list", pathMatch: "full" },
      {
        path: 'list',
        loadChildren: () => import('./tabs-order/tabs-order.module').then(m => m.TabsOrderModule)
      },
      {
        path: 'detail/:id',
        loadChildren: () => import('./detail-order/detail-order.module').then(m => m.DetailOrderModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrderRoutingModule { }
