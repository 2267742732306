import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class SharingServices {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }


  getList() : Promise<any> {
    return this.httpService.get(`admin/shares/list`);
  }

  storeWs(data: any) : Promise<any> {
    return this.httpService.post2(`admin/shares`, {id_share_type: "sharewhatsapp",  json : data})
  }
  storeFb(data: any) : Promise<any> {
    let params : HttpParams = new HttpParams()

    .append('json', JSON.stringify(data));
    return this.httpService.post(`admin/shares`, params)
  }

  update(share_id: number, form: any) : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('id_share', String(share_id))
    .append('json', JSON.stringify(form));

    return  this.httpService.put(`admin/shares/update`, params)
  }

  delete(store_id:any,share_id: number) : Promise<any> {
    return  this.httpService.post(`admin/shares/${store_id}/trash`,{id:String(share_id)})
  }
    
  getOrder(id_order: string) : Promise<any> {

    return this.httpService.get(`cartsshares/${id_order}/detail`)

  }
  
}
