import { ICategory } from 'app/models/category';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }

  add(category: ICategory) : Promise<any> {
    return this.httpService.post(`admin/categories`, category);
  }

  getListPage(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search);

    return this.httpService.get(`admin/categories/list`, params)
  }

  getFullListPage() : Promise<any> {
    return this.httpService.get(`admin/categories/fullList`)
  }

  getProductBySku(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/categories/${sku}/detail`);
  }

  updateStatusById(id: string, status: string) : Promise<any> {
    return this.httpService.put(`admin/categories/${id}/status`, {status: status});
  }

}
