import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpService)
  {}

  public upload(data: any)
  {
		const formData = new FormData();
		formData.append('file', data);
		return this.http.postFile('admin/files', formData);
	}

  public uploadPdf(data:any){
    const formData = new FormData();
		formData.append('file', data);
		return this.http.postFile('admin/home/catalogo', formData);
  }


}
