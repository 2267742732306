import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }

  getListPage(page: number = 1, search:any  = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('search', String(search));

    return this.httpService.get(`admin/customers/list`,  params)
  }

  getOrderByUoc(ucc: string) : Promise<any> {
    return this.httpService.get(`admin/customers/${ucc}/detail`);
  }

  trashOrderByUoc(ucc: string) : Promise<any> {
    return this.httpService.put(`admin/customers/${ucc}/trash`);
  }

  restoreOrderTrashByUoc(ucc: string) : Promise<any> {
    return this.httpService.put(`admin/customers/${ucc}/restore`);
  }

}
