import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationServices {

  private borrador: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }


  getList() : Promise<any> {
    return this.httpService.get(`admin/reservations/list`);
  }

  getListPage(page: number = 1, status: any  = "all", type: any  = "all", search: any  = "") : Promise<any> {
    return this.httpService.get(`admin/reservations/listorders`, {page: page, status: status, type: type, search: search})
  }

  store(data: any) : Promise<any> {
    return this.httpService.post2(`admin/reservations`, { json : data})
  }

  update(share_id: number, form: any) : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('id_share', String(share_id))
    .append('json', JSON.stringify(form));

    return  this.httpService.put(`admin/reservations/update`, params)
  }

  delete(store_id:any,share_id: number) : Promise<any> {
    return  this.httpService.post(`admin/reservations/${store_id}/trash`,{id:String(share_id)})
  }
    
  nofityWithdraw(id: string, nota: string) : Promise<any> {
    return this.httpService.put(`admin/reservations/${id}/withdraw`, {nota: nota});
  }
  nofityWithdrawPlus(id: string, nota: string,descripcion:string,importe:string) : Promise<any> {
    return this.httpService.put(`admin/reservations/${id}/withdrawAdd`, {nota: nota,description:descripcion,importe:importe});
  }
  nofityCancel(id: string ): Promise<any> {
    return this.httpService.put(`admin/reservations/${id}/setCancelStatus`, {});
  }
  
}
